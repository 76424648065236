<template>
  <section title="">
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-card-body>
            <b-row>
              <b-col md="9">
                <h2>Produtos</h2>
              </b-col>
              <b-col md="3">
                <b-button variant="gradient-success" class="mb-75" fixed block @click="AdicionarProdutos()"> Adicionar
                </b-button>
              </b-col>
            </b-row>
            <data-table :data="data" :columns="columns" @on-table-props-changed="reloadTable">
              <tbody slot="body" slot-scope="{
                  // eslint-disable-next-line vue/no-template-shadow
                  data }">
                <tr v-for="item in data" :key="item.id">
                  <td v-for="column in columns" :key="column.name">
                    <data-table-cell :value="item" :name="column.name" :meta="column.meta" :comp="column.component"
                      :classes="column.classes" />
                    <slot v-if="column.label === 'Ações'">
                      <b-button variant="outline-primary" size="sm" class="btn-icon rounded-circle"
                        @click="edicao(item)">
                        <feather-icon icon="EditIcon" />
                      </b-button>
                      <b-button variant="outline-warning" size="sm" class="btn-icon rounded-circle"
                        @click="abreEstoque(item)">
                        <feather-icon icon="DatabaseIcon" />
                      </b-button>
                    </slot>
                    <slot v-if="column.label === 'Estoque'">
                      <b-badge :variant="quantityForm(item).cor">
                        {{  quantityForm(item).msg  }}
                      </b-badge>
                    </slot>
                    <slot v-if="column.label === 'Status'">
                      <b-badge :variant="NomeStatus(item).cor">
                        {{  NomeStatus(item).msg  }}
                      </b-badge>
                    </slot>
                  </td>
                </tr>
              </tbody>
            </data-table>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>
    <b-modal id="modal-estoque" centered title="Estoque" modal-class="modal-warning" hide-footer>
      <b-row>
        <b-col md="12">
          <div class="d-flex">
            <h5>Estoque atual = <i><u>{{  estoque_inicial  }}</u></i></h5>
            <h5 style="margin-left: 30px">
              Novo estoque = <i><u>{{  estoque_atual  }}</u></i>
            </h5>
          </div>
        </b-col>
        <b-col md="9">
          <b-form-group label="Estoque (adicione ou remova valores)" label-for="estoque">
            <b-form-input id="estoque" :value="estoque" placeholder="Estoque " @input="alteraValor($event)" />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-button style="margin-top: 20px" variant="flat-primary" pill block @click="enviaEstoque()">
            Enviar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </section>
</template>
<script>
import { heightTransition } from '@core/mixins/ui/transition'
import { VBToggle } from 'bootstrap-vue'
import Vue from 'vue'
import vSelect from 'vue-select'

Vue.component('v-select', vSelect)

export default {
  directives: {
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  data() {
    return {
      id: null,
      categoriaTipo: '',
      nomeCategorias: '',
      descCategorias: '',
      mostraPopUp: true,
      url: 'products/getall',
      data: {},
      dadositem: {},
      tableProps: {
        search: '',
        length: 10,
        column: 'id',
        dir: 'asc',
      },
      opcoesTipo: [
        { value: 1, text: 'Ativo' },
        { value: 2, text: 'Inativo' },
      ],
      columns: [
        {
          label: 'Nome',
          name: 'name',
          orderable: false,
        },
        {
          label: 'Estoque',
          orderable: false,
        },
        {
          label: 'Preço',
          name: 'price',
          orderable: false,
        },
        {
          label: 'Status',
          orderable: false,
        },
        {
          label: 'Ações',
          filterable: false,
        },
      ],
      estoque: '',
      estoque_inicial: '',
      estoque_atual: 0,
      codigo_estoque: '',
    }
  },
  created() {
    this.BuscarDados(this.url)
    this.$loading(true)
  },
  methods: {
    enviaEstoque() {
      const obj = {
        estoque: this.estoque_atual,
      }
      this.$http.post(`products/updatestock/${this.codigo_estoque}`, obj).then(resp => {
        this.resposta = resp.data
        if (this.resposta.success) {
          this.$toast.success('Estoque atualizado')
          this.$bvModal.hide('modal-estoque')
          this.reloadTable()
        } else {
          this.$toast.error('Erro no estoque')
        }
      })
    },
    alteraValor(valor) {
      this.estoque_atual = valor
      if (this.estoque_inicial === 'Sem estoque') {
        this.estoque_atual = parseFloat(valor)
      } else {
        this.estoque = parseFloat(valor)
        this.estoque_atual = parseFloat(this.estoque + parseFloat(this.estoque_inicial))
      }
    },
    abreEstoque(item) {
      this.codigo_estoque = item.id
      this.LimpaEstoque()
      this.$bvModal.show('modal-estoque')
      this.estoque_inicial = item.stock.quantity
      if (item.stock.quantity === null) {
        this.estoque_inicial = 'Sem estoque'
      }
    },
    LimpaEstoque() {
      this.estoque = ''
      this.estoque_atual = ''
    },
    edicao(item) {
      this.$loading(true)
      localStorage.setItem('produto', JSON.stringify(item))
      this.$router.push(`/produtos/editar/${item.id}`)
    },

    AdicionarProdutos() {
      this.$router.push({
        name: 'app-addprodutos',
      })
    },
    quantityForm(item) {
      const { stock } = item
      if (stock.migrated === 1) {
        if (Number(stock.quantity) === 0) {
          return { cor: 'light-danger', msg: `${stock.quantity}/${item.minimumStock ? item.minimumStock : 0}` }
        } if (Number(stock.quantity) < 0) {
          return { cor: 'danger', msg: `${stock.quantity}/${item.minimumStock ? item.minimumStock : 0}` }
        } if (Number(stock.quantity) < Number(item.minimumStock ? item.minimumStock : 0)) {
          return { cor: 'warning', msg: `${stock.quantity}/${item.minimumStock ? item.minimumStock : 0}` }
        } if (Number(stock.quantity) === Number(item.minimumStock ? item.minimumStock : 0)) {
          return { cor: 'light-warning', msg: `${stock.quantity}/${item.minimumStock ? item.minimumStock : 0}` }
        } if (Number(stock.quantity) > Number(item.maximumStock ? item.maximumStock : 0)) {
          return { cor: 'success', msg: `${stock.quantity}/${item.maximumStock ? item.maximumStock : 0}` }
        } if (Number(stock.quantity) > Number(item.minimumStock ? item.minimumStock : 0)) {
          return { cor: 'light-success', msg: `${stock.quantity}/${item.maximumStock ? item.maximumStock : 0}` }
        } return { cor: 'light-secondary', msg: stock.quantity }
      }
      if (stock.migrated === 0) {
        return { cor: 'light-secondary', msg: '...' }
      } return ''
    },
    NomeStatus(item) {
      if (item.updated === 2) {
        return { cor: 'danger', msg: 'Dessincronizado' }
      }
      if (item.situation === 'A' && item.updated === 1) {
        return { cor: 'success', msg: 'Ativo' }
      }
      if (item.updated === 1) {
        return { cor: 'light-success', msg: 'sincronizado' }
      }
      if (item.updated === 0) {
        return { cor: 'light-secondary', msg: 'Sincronizando' }
      } return 'Sem Status'
    },
    async BuscarDados(url = this.url, options = this.tableProps) {
      await this.$http.get(url, { params: options }).then(resp => {
        this.data = resp.data
      })
      this.$loading(false)
    },
    reloadTable(tableProps) {
      this.$loading(true)
      this.mostraPopUp = false
      this.BuscarDados(this.url, tableProps)
    },
    async DeletarDados(item) {
      this.$http.delete(`bling_produto_deletar/${item.codigo}`)
      await this.reloadTable()
      this.$toast.success('Produto Excluido Com Sucesso')
    },
  },
}
</script>
<style>
.table td {
  line-height: 1.4 !important;
}
</style>
